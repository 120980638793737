import NumberFormater from '../../helpers/number_formater'
import { translatePaymentConditions } from '../../helpers/order_utils'

export default {
  data: () => ({
    id: null,
    distributor: null,
    payment: null,
    isPrinting: false
  }),
  computed: {
    order() {
      return this.$store.state.order.view
    },
    products() {
      return this.$store.state.order.inserter.rows
    },
    canEdit() {
      if (this.order) {
        const enableEditStatus = [2, 5]
        return enableEditStatus.includes(this.order.status)
      }
      return false
    },
    paymentTrasnlation() {
      return translatePaymentConditions(this.order.payment, this.language, this.order.payment_method_id)
    }
  },
  created() {
    this.language = localStorage.getItem('currentLang') || 'pt';
    this.id = this.$route.params.id
    this.getData()
  },
  mounted() {
    if (!document.getElementById('jspdf_debug')) {
      let jsdebugScript = document.createElement('script')
      jsdebugScript.setAttribute('id', 'jspdf_debug-cdn-polyfill')
      jsdebugScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.4.1/jspdf.debug.js')
      document.head.appendChild(jsdebugScript)
    }
  },
  watch: {
    'order.distributor_id': function (value) {
      this.getDistributor(value)
    },
    'order.payment_method_id': function (value) {
      console.log(value)
      this.getPayment(value)
    },
    '$route.name': function (value) {
      if (value == 'order-create') {
        this.$store.dispatch('order/clear', 'view')
      }
    }
  },
  methods: {
    format(value) {
      if (value) {
        return NumberFormater.money(value, { decimalLength: 2 })
      } else {
        return null
      }
    },
    back() {
      this.$router.push({
        name: 'order-list'
      })
    },
    edit() {
      this.$router.push({
        name: 'order-edit',
        params: {
          orderDataID: this.id
        }
      })
    },
    async remove() {
      var response = await this.$store.dispatch("dialog/show", {
        title: 'Remover',
        content: 'Você deseja remover esse rascunho de pedido?',
      })
      if (response) {
        await this.$store.dispatch('order/remove', this.id)
        this.$router.push({
          name: 'order-list'
        })
      }
    },
    async removeOrder() {
      var response = await this.$store.dispatch("dialog/show", {
        title: 'Remover',
        content: 'Você deseja remover esse pedido?',
      })
      if (response) {
        await this.$store.dispatch('order/remove_order', this.id)
        this.$router.push({
          name: 'order-list'
        })
      }
    },
    async getData() {
      this.$store.dispatch('system/loading', 'data')
      const data = await this.$store.dispatch('order/get', this.id)
      this.$store.dispatch('system/loading', 'done')
    },
    async getDistributor(id) {
      const { body } = await this.$store.dispatch('distributor/getClean', id)
      this.distributor = body.name
    },
    async getPayment(id) {
      await this.$store.dispatch('paymentMethods/get', id)
      let body = this.$store.state.paymentMethods.view
      this.payment = body.description[this.language]
    },
    pdfPrint() {
      this.isPrinting = true

      setTimeout(() => {
        var doc = new jsPDF()
        var specialElementHandlers = {
          '#getPDF': function (element, renderer) {
            return true
          },
          '.controls': function (element, renderer) {
            return true
          }
        }

        doc.fromHTML(
          document.getElementById('forPrint'),
          15,
          15,
          {
            'width': 1000,
            'elementHandlers': specialElementHandlers
          }
        )

        doc.save('generated.pdf')
      }, 500)


      setTimeout(() => {
        this.isPrinting = false
      }, 1000)
    }
  },
  destroyed() {
    this.$store.dispatch('order/clear', 'view')
  }
}
